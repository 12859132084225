import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'
import { Vuelos } from '../models/vuelos.modelo';

@Injectable({
  providedIn: 'root'
})
export class VueloService {

  constructor(public http: HttpClient) { }

  uploadVuelos(fileToUpload: File): Observable<any> {
    const endpoint = `${environment.APIEndpoint}/admin/upload_vuelos`;
    const formData: FormData = new FormData();
    formData.append('excel', fileToUpload, fileToUpload.name);
    return this.http.post(endpoint, formData);
  }

  getVuelos(): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/admin/vuelos`);
  }

  deleteVuelos(id_vuelo: number): Observable<any> {
    return this.http.delete(`${environment.APIEndpoint}/admin/eliminar_vuelo/${id_vuelo}`);
  }

  createVuelos(vuelo: Vuelos): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/crear_vuelo`, vuelo);
  }

  updateVuelos(vuelo: Vuelos): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/actualizar_vuelo`, vuelo);
  }
}
