import { Component, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Reserva } from '../models/reserva.modelo';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { DataReservaService } from '../services/data-reserva.service';
import { Router } from '@angular/router';
import { FormatReservaService } from '../services/format-reserva.service';
import { AlertasService } from '../services/alertas.service';



let RESERVAS = [];

function search(text: string, pipe: PipeTransform): any[] {
  return RESERVAS.filter(reserva => {
    const term = text.toLowerCase();
    reserva.vuelo != null ? reserva.vuelo = reserva.vuelo : reserva.vuelo = '';
    reserva.id_orden_compra = String(reserva.id_orden_compra);
    return reserva.id_orden_compra.toLowerCase().includes(term)
      || reserva.servicio.toLowerCase().includes(term)
      || reserva.nombre.toLowerCase().includes(term)
      // || reserva.direccion.toLowerCase().includes(term)
      || reserva.fecha_servicio.toLowerCase().includes(term)
      || reserva.hora_servicio.toLowerCase().includes(term)
      || reserva.vuelo.toLowerCase().includes(term)
      || reserva.trayecto.toLowerCase().includes(term);
  });
}


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  providers: [DecimalPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MainComponent implements OnInit {

  reservas$: Observable<any[]>;
  verReservasAnuladas: boolean = false;
  filter = new FormControl('');
  valoresReserva: Reserva = new Reserva();
  valoresReserva1: Reserva = new Reserva();
  valoresReserva2: Reserva = new Reserva();
  reserva: Reserva = new Reserva();

  fecha1: string = null;
  fecha2: string = null;

  constructor(
    public router: Router,
    private reservas: DataReservaService,
    private pipe: DecimalPipe,
    private fmtReservaServ: FormatReservaService,
    private alertService: AlertasService) {
  }

  ngOnInit() {
    this.reservas.getReservasMes().subscribe(
      res => {
        if (!this.verReservasAnuladas) {
          RESERVAS = res.data.filter(x => {
            return x.estado_pago == 1
          });
        } else {
          RESERVAS = res.data;
        }
        console.log(RESERVAS);
        this.cargaTablas();
      },
      error => {
        console.log('ERROR GET RESERVAS');
      });
  }


  // tslint:disable-next-line: variable-name
  detalleReserva(id_orden: number) {
    const data = RESERVAS.filter(reserva => {
      return reserva.id_reserva === id_orden;
    });

    const res = data[0];

    if (res.tipo_reserva === 'especial-ida' || res.tipo_reserva === 'especial-ida-vuelta') {
      this.reserva = this.fmtReservaServ.formatDataClassReserva(res);
      this.reservas.setReserva3(this.reserva);
      this.router.navigateByUrl('admin/detalle_especial');

    }

    if (res.tipo_reserva === 'normal-ida' || res.tipo_reserva === 'normal-ida-vuelta') {
      this.reserva = this.fmtReservaServ.formatDataClassReserva(res);
      this.reservas.setReserva1(this.reserva);
      this.router.navigateByUrl('admin/detalle_normal');

    }
    if (res.tipo_reserva === 'transfer-ida' || res.tipo_reserva === 'transfer-ida-vuelta') {
      this.reserva = this.fmtReservaServ.formatDataClassReserva(res);
      this.reservas.setReserva1(this.reserva);
      this.router.navigateByUrl('admin/detalle_normal');

    }
  }

  cargaTablas() {
    if (RESERVAS) {
      setTimeout(() => {
        this.reservas$ = this.filter.valueChanges.pipe(
          startWith(''),
          map(text => search(text, this.pipe))
        );
      }, 20);
    } else {
      this.alertService.send('NO SE ENCUENTRAN REGISTROS EN LA BÚSQUEDA', 'alert');
    }
  }


  rangoFechaReserva() {
    if (this.fecha1 && this.fecha2) {
      this.reservas.getReservasRango(this.fecha1, this.fecha2).subscribe(
        res => {
          if (!this.verReservasAnuladas) {
            RESERVAS = res.data.filter(x => {
              return x.estado_pago == 1
            });
          } else {
            RESERVAS = res.data;
          }
          this.cargaTablas();
        },
        error => {
          this.alertService.send('NO EXISTEN REGISTROS', 'alert');
        }
      );
    } else {
      this.alertService.send('FALTA UN CAMPO DE FECHA', 'warning');
    }
  }

  formatCantPasajeros(pasajeros: any): string {
    const adultos = pasajeros.adultos ? pasajeros.adultos : '0';
    const menores = pasajeros.menores ? pasajeros.menores : '0';
    const infantes = pasajeros.infantes ? pasajeros.infantes : '0';
    return `${adultos}a+${menores}m+${infantes}i`;
  }

  getFecha1(event: MatDatepickerInputEvent<Date>) {
    const date = moment(event.value);
    this.fecha1 = date.format('YYYY-MM-DD');
  }

  getFecha2(event: MatDatepickerInputEvent<Date>) {
    const date = moment(event.value);
    this.fecha2 = date.format('YYYY-MM-DD');
  }

  generarReservaEspecial() {
    this.router.navigateByUrl('admin/generar_especial');
  }

  generarReservaNormal() {
    this.router.navigateByUrl('admin/generar_normal');
  }

}
