import { Component, OnInit } from '@angular/core';
import { AlertasService } from '../services/alertas.service';
import { FileUploadService } from '../services/file-upload.service';

@Component({
  selector: 'app-banner-home',
  templateUrl: './banner-home.component.html',
  styleUrls: ['./banner-home.component.css']
})
export class BannerHomeComponent implements OnInit {

  constructor(public fileUploadService: FileUploadService, private alertService: AlertasService) { }

  tipoImg: string;

  fileToUpload: File = null;

  ngOnInit() {
    this.tipoImg = 'img-1';
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this.fileUploadService.postFile(this.fileToUpload, this.tipoImg).subscribe(data => {
      this.alertService.send('CARGA DE IMAGEN COMPLETADA', 'success');
    }, error => {
      this.alertService.send('ERROR AL CARGAR IMAGENES', 'alert');
    });
  }

}
