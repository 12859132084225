import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ZonasService } from '../services/zonas.service';

export interface ZonaData {
  id_zona: number;
  titulo: string;
  coordenadas: { lat: number; lng: number }[] | string;
  tiempo_estimado: number;
  tiempo_estimado_unidad: string;
  valor_zona_extra: number;
  restriccion_ruta: string;
  habilitar_reserv_transfer_comp: number;
}

@Component({
  selector: 'app-modal-edit-zonas',
  templateUrl: './modal-edit-zonas.component.html',
  styleUrls: ['./modal-edit-zonas.component.css']
})
export class ModalEditZonasComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalEditZonasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ZonaData,
    private router: Router, private zonaService: ZonasService) { }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  deletePoly(data): void {
    const confm = confirm('Selecciona aceptar para eliminar la zona');
    this.dialogRef.close();
    if (confm) {
      if (data.id_zona !== null || data.id_zona !== undefined) {
        this.zonaService.deleteZona(data.id_zona).subscribe(
          res => {
            this.redirectTo('admin/zonas');
          },
          error => {
            console.log(error);
          });
      } else {
        this.redirectTo('admin/zonas');
      }
    }
  }

  ngOnInit() {
  }

}
