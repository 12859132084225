import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertasService } from '../services/alertas.service';
import { UsuariosService } from '../services/usuarios.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  constructor(private router: Router, private alertService: AlertasService, private userService: UsuariosService) { }

  password: string;
  user: string;

  ingreso() {
    this.userService.login(this.user, this.password).subscribe(
      res => {

        sessionStorage.setItem('user_token', res.user_token);
        sessionStorage.setItem('user_name', res.nombre_usuario);
        sessionStorage.setItem('user_id', res.id_usuario);
        sessionStorage.setItem('user_type', res.tipo_usuario);

        if (res.tipo_usuario === 'administrador') {
          this.router.navigateByUrl('admin/main');
        }
        if (res.tipo_usuario === 'chofer') {
          this.router.navigateByUrl('chofer');
        }
        if (res.tipo_usuario === 'operador') {
          /* codigo operador */
        }
      },
      error => {
        this.alertService.send('ERROR EN SESSION', 'alert');
      }
    );
  }

  ngOnInit() {
  }

}
