import { Component, OnInit } from '@angular/core';
import { Configuracion } from '../models/variables-configuracion.modelo';
import { ConfiguracionService } from '../services/configuracion.service';
import { Router } from '@angular/router';
import { AlertasService } from '../services/alertas.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {

  config: Configuracion = new Configuracion();


  constructor(
    private alertService: AlertasService,
    private dataConfiguracion: ConfiguracionService,
    public router: Router
  ) {
    dataConfiguracion.getVariablesConfig().subscribe(
      res => {
        const varConfig = res.data[0];
        this.config = { ...varConfig };
      },
      error => {
        this.alertService.send('ERROR AL CONSURTAR VARIABLES DE CONFIGURACIÓN', 'alert');
      });
  }

  ngOnInit() {
  }

  guardarConfiguracion() {
    for (const key in this.config) {
      if (this.config[key] === undefined || this.config[key] === '' || this.config[key] === '') {
        this.config[key] = 0;
      }
    }

    this.dataConfiguracion.saveVariablesConfig(this.config).subscribe(
      res => {
        this.alertService.send('CONFIGURACION GUARDADA', 'success');
      },
      error => {
        this.alertService.send('ERROR AL GUARDAR CONFIGURACION', 'alert');
      });
  }

  salirModuloConfig() {
    this.router.navigateByUrl('admin/main');
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
