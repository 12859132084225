import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zerofill'
})
export class ZerofillPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    if (typeof args === 'number') {
      let aux: string = String(value);
      while (aux.length < args) {
        aux = '0' + aux;
      }
      return aux;
    }
    return null;
  }

}
