import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { ZonaData } from '../models/zonadata.modelo';

@Injectable({
  providedIn: 'root'
})
export class TarifaService {

  constructor(public http: HttpClient, private mapsAPILoader: MapsAPILoader) { }

  getZonas(): Observable<ZonaData[]> {
    return this.http.get(`${environment.APIEndpoint}/cliente/get_zonas`).pipe(
      map((zonas: any) => zonas.data.map((x: any) => {
        x.coordenadas = this.formatCoordenadas(x.coordenadas);
        return x;
      })),
      catchError(err => {
        return throwError(err);
      }),
    );
  }

  constainLocationZone(zona: ZonaData[], lat: number, lng: number): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        zona.forEach(x => {
          const point = new google.maps.LatLng(lat, lng);
          const coordenadas = new google.maps.Polygon({ paths: x.coordenadas });
          const resultPath = google.maps.geometry.poly.containsLocation(
            point,
            coordenadas
          );
          if (resultPath) {
            resolve(x);
          }
        });
        reject(new Error('Coordenada no existe en zona'));
      });
    });
    return promise;
  }

  formatCoordenadas(coordenadas: any): any {
    const coords: Array<any> = [];
    const points = coordenadas.split('|');
    points.forEach(e => {
      const x = e.split(',');
      coords.push({ lat: Number(x[0]), lng: Number(x[1]) });
    });
    return coords;
  }

  getAuto(lat: number, lng: number, detalle: string, zona?: ZonaData): Observable<any> {
    let direccion = null;
    const latitud: string = this.coordenadaFormatApi(lat);
    const longitud: string = this.coordenadaFormatApi(lng);

    if (detalle === 'normal-ida') { direccion = 'ida'; }
    if (detalle === 'normal-ida-vuelta') { direccion = 'ida-vuelta'; }

    return this.http.get(`${environment.APIEndpoint}/tarifa/normal/${latitud}/${longitud}/auto-pv/${direccion}/${zona ? zona.id_zona : 'false'}`);
  }

  getVan(lat: number, lng: number, detalle: string, zona?: ZonaData): Observable<any> {

    const latitud: string = this.coordenadaFormatApi(lat);
    const longitud: string = this.coordenadaFormatApi(lng);

    if (detalle === 'normal-ida') { detalle = 'ida'; }
    if (detalle === 'normal-ida-vuelta') { detalle = 'ida-vuelta'; }

    return this.http.get(`${environment.APIEndpoint}/tarifa/normal/${latitud}/${longitud}/van-pv/${detalle}/${zona ? zona.id_zona : 'false'}`);
  }

  getEspecial(lat1: number, lng1: number, lat2: number, lng2: number, servicio: string, detalle: string): Observable<any> {

    const latitud1: string = this.coordenadaFormatApi(lat1);
    const longitud1: string = this.coordenadaFormatApi(lng1);
    const latitud2: string = this.coordenadaFormatApi(lat2);
    const longitud2: string = this.coordenadaFormatApi(lng2);


    return this.http.get(`${environment.APIEndpoint}/tarifa/especial/${latitud1}/${longitud1}/${latitud2}/${longitud2}/${servicio}/${detalle}`);
  }

  getTransfer(adultos: number, menores: number, zona?: ZonaData): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/tarifa/transfer/${adultos}/${menores}/${zona ? zona.id_zona : 'false'}`);
  }

  coordenadaFormatApi(coordenada: number): string {
    const coord = Number(coordenada).toFixed(6);
    const x: string = coord.toString();
    const coordenadaStr = x.replace('.', '_');
    return coordenadaStr;
  }
}

