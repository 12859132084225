export class Usuario {
  idUsuario: number;
  nombre: string;
  telefono: string;
  email: string;
  tipoUsuario: string;
  usuario: string;
  password: string;
  confirmarPassword: string;
}
