import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RutasService {

  constructor(private http: HttpClient) { }

  queryRutas(queryRuta: any): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/consulta_rutas`, queryRuta);
  }

  asignarRuta(variables: any): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/asignar_ruta`, variables);
  }

  cancelarRuta(id: number): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/cancelar_ruta`, { id_reserva: id });
  }

  finalizarRuta(id: number): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/finalizar_ruta`, { id_reserva: id });
  }
}
