import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';

import {
  MatDatepickerModule,
  MatTableModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatDialogModule
} from '@angular/material';
import { MatNativeDateModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule
} from '@angular/material';
import { MainComponent } from './main/main.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// SERVICIOS
import { DataReservaService } from './services/data-reserva.service';
import { HttpClientModule } from '@angular/common/http';
import { ConfiguracionService } from './services/configuracion.service';

// Biblioteca Google Map Angular
import { AgmCoreModule } from '@agm/core';

import { MapasGoogleMapComponent } from './mapas-google-map/mapas-google-map.component';
import { DetalleReservaComponent } from './detalle-reserva/detalle-reserva.component';
import { GenerarReservaComponent } from './generar-reserva/generar-reserva.component';
import { DetalleReservaEspecialComponent } from './detalle-reserva-especial/detalle-reserva-especial.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { BannerHomeComponent } from './banner-home/banner-home.component';
import { FileUploadService } from './services/file-upload.service';
import { VuelosComponent } from './vuelos/vuelos.component';
import { VueloService } from './services/vuelo.service';
import { RutasComponent } from './rutas/rutas.component';
import { UsuariosService } from './services/usuarios.service';
import { RutasService } from './services/rutas.service';
import { ChoferComponent } from './chofer/chofer.component';
import { ZerofillPipe } from './pipes/zerofill.pipe';
import { GenerarReservaNormalComponent } from './generar-reserva-normal/generar-reserva-normal.component';
import { ZonasComponent } from './zonas/zonas.component';
import { ModalEditZonasComponent } from './modal-edit-zonas/modal-edit-zonas.component';
import { AlertasService } from './services/alertas.service';
import { TarifaService } from './services/tarifa.service';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SignInComponent,
    AdminComponent,
    UserComponent,
    MapasGoogleMapComponent,
    DetalleReservaComponent,
    GenerarReservaComponent,
    DetalleReservaEspecialComponent,
    ConfiguracionComponent,
    BannerHomeComponent,
    VuelosComponent,
    RutasComponent,
    ChoferComponent,
    ZerofillPipe,
    GenerarReservaNormalComponent,
    ZonasComponent,
    ModalEditZonasComponent
  ],
  entryComponents: [ModalEditZonasComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatInputModule,
    MatSnackBarModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatChipsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC0rhjQ6Cz1Ic3CumOCCYssBvH6oL2SPKw',
      libraries: ['places', 'drawing', 'geometry']
    })
  ],
  providers: [
    DataReservaService,
    ConfiguracionService,
    FileUploadService,
    VueloService,
    UsuariosService,
    RutasService,
    AlertasService,
    TarifaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
