import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Usuario } from '../models/usuario.modelo';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  constructor(private http: HttpClient) { }

  login(user: string, pass: string): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/login`, { usuario: user, password: pass });
  }

  getUsuarios(): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/usuarios`, { token: environment.TOKEN });
  }

  getChoferes(): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/admin/choferes`);
  }

  deleteUsuario(idUsuario: number): Observable<any> {
    return this.http.delete(
      `${environment.APIEndpoint}/admin/eliminar_usuario/${idUsuario}`
    );
  }

  crearUsuario(usuario: Usuario): Observable<any> {
    return this.http.post(
      `${environment.APIEndpoint}/admin/crear_usuario`,
      usuario
    );
  }

  updateUsuario(usuario: Usuario): Observable<any> {
    return this.http.post(
      `${environment.APIEndpoint}/admin/update_usuario`,
      usuario
    );
  }
}
