import { Component, OnInit, Injectable } from '@angular/core';
import { DataReservaService } from '../services/data-reserva.service';
import { Reserva } from '../models/reserva.modelo';
import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDatepickerI18n, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AlertasService } from '../services/alertas.service';
import { ZonaData } from '../models/zonadata.modelo';
import { TarifaService } from '../services/tarifa.service';
import { forkJoin } from 'rxjs';

const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    monthsLg: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  }

};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


/**
 * FORMATO HORA TIMEPICKER
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

@Component({
  selector: 'app-generar-reserva-normal',
  templateUrl: './generar-reserva-normal.component.html',
  styleUrls: ['./generar-reserva-normal.component.css'],
  providers: [I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class GenerarReservaNormalComponent implements OnInit {

  // DATEPICKER
  model_ida_fecha: NgbDateStruct;
  model_idaVuelta_fecha1: NgbDateStruct;
  model_idaVuelta_fecha2: NgbDateStruct;

  public meridian = true;

  zonaData: ZonaData[];

  dia: string;
  mes: string;
  year: string;

  model: any;

  reserva: Reserva = new Reserva();

  vistaServicioIdaVuelta = false;

  lat = -39.813968;
  lng = -73.234897;

  constructor(
    public dataReserva: DataReservaService,
    private calendar: NgbCalendar,
    private alertService: AlertasService,
    private router: Router,
    private tarifaService: TarifaService
  ) {
  }

  ngOnInit() {
    this.reserva.opcionPago = 'vehiculo';
    this.reserva.tipoServicio = 'auto-pv';
    this.reserva.sentidoViaje = 'v-a';
    this.reserva.estadoPago = false;
    this.reserva.direccionOrigenLat = -39.813968;
    this.reserva.direccionDestinoLat = -39.813968;
    this.reserva.direccionOrigenLng = -73.234897;
    this.reserva.direccionDestinoLong = -73.234897;
    this.reserva.tarifa = 0;
    this.tarifaService.getZonas().subscribe(
      res => { this.zonaData = res; },
      err => this.alertService.send('ERROR AL INTENTAR OBTENER MATRIZ DE ZONAS')
    );
  }

  selectToday() {
    this.model_ida_fecha = this.calendar.getToday();
    this.model_idaVuelta_fecha1 = this.calendar.getToday();
    this.model_idaVuelta_fecha2 = this.calendar.getToday();
    this.reserva.fechaIda = this.ngbDateToString(this.calendar.getToday());
    this.reserva.fechaVuelta = this.ngbDateToString(this.calendar.getToday());
  }

  calendarFechaIda(date: NgbDate) {
    this.reserva.fechaIda = this.ngbDateToString(date);
  }

  calendarFechaVuelta(date: NgbDate) {
    this.reserva.fechaVuelta = this.ngbDateToString(date);
  }

  ngbDateToString(date: NgbDate): string {
    return `${date.year}-${date.month}-${date.day}`;
  }

  salirModuloEdicionReserva() {
    this.router.navigateByUrl('admin/main');
  }

  cleanSelectDetallServ() {
    this.reserva.detalleServicio = null;
  }

  getGeoDireccionOrigen(event): void {
    this.reserva.direccionOrigen = event.direccion;
    this.reserva.direccionOrigenLat = event.latitud;
    this.reserva.direccionOrigenLng = event.longitud;
  }

  getGeoDireccionDestino(event): void {
    this.reserva.direccionDestino = event.direccion;
    this.reserva.direccionDestinoLat = event.latitud;
    this.reserva.direccionDestinoLong = event.longitud;
  }

  generarReservaNormal() {
    if (
      this.reserva.nombrePasajero &&
      this.reserva.telefonoPasajero &&
      this.reserva.emailPasajero &&
      this.reserva.tarifa !== 0 &&
      this.reserva.fechaIda &&
      this.reserva.horaRecogida &&
      this.reserva.direccionOrigen
    ) {
      this.dataReserva.createReservaNormal(this.reserva).subscribe(
        res => {
          this.alertService.send('RESERVA GENERADA');
        },
        errror => {
          this.alertService.send('ERROR AL GENERAR RESERVA');
        });
    } else {
      this.alertService.send('ERROR FALTA UN CAMPO DE DATOS');
    }
  }

  tarifa(): void {
    this.reserva.tarifa = 0;
    if (this.reserva.detalleServicio === 'normal-ida' || this.reserva.detalleServicio === 'transfer-ida') {
      if (
        this.reserva.adultos &&
        this.reserva.tipoServicio &&
        this.reserva.detalleServicio &&
        this.reserva.direccionOrigen &&
        this.reserva.direccionOrigenLat &&
        this.reserva.direccionDestinoLong
      ) {
        if (this.reserva.tipoServicio === 'transfer') {
          this.scanZonaTransfer();
        }
        if (this.reserva.tipoServicio === 'auto-pv' || this.reserva.tipoServicio === 'van-pv') {
          this.scanZonaNormal();
        }
      } else {
        this.alertService.send('FALTA UN CAMPO PARA CALCULAR TARIFA');
      }
    } else {
      if (
        this.reserva.adultos &&
        this.reserva.tipoServicio &&
        this.reserva.detalleServicio &&
        this.reserva.direccionOrigen &&
        this.reserva.direccionOrigenLat &&
        this.reserva.direccionDestinoLong &&
        this.reserva.direccionDestino &&
        this.reserva.direccionDestinoLat &&
        this.reserva.direccionDestinoLong
      ) {
        if (
          this.reserva.tipoServicio === 'transfer' &&
          this.reserva.detalleServicio === 'transfer-ida-vuelta'
        ) {
          this.scanZonaTransferIdaVuelta();
        }
        if (
          this.reserva.tipoServicio === 'auto-pv' ||
          this.reserva.tipoServicio === 'van-pv' &&
          this.reserva.detalleServicio === 'normal-ida-vuelta'
        ) {
          this.scanZonaNormalIdaVuelta();
        }
      } else {
        this.alertService.send('FALTA UN CAMPO PARA CALCULAR TARIFA');
      }
    }
  }

  scanZonaTransfer() {
    this.tarifaService.constainLocationZone(this.zonaData, this.reserva.direccionOrigenLat, this.reserva.direccionOrigenLng).then(
      (zona: ZonaData) => {
        this.reserva.zonaIda = zona;
        if (this.reserva.zonaIda.habilitar_reserv_transfer_comp) {
          this.getTarifa();
        } else {
          this.alertService.send('DIRECCIÓN EN ZONA INHABILITADA PARA TRANSFER COMPARTIDO');
        }
      }
    ).catch((err) => {
      this.cleanZonaReserva();
      this.alertService.send('LA DIRECCIÓN NO SE ENCUENTRA DISPONIBLE PARA SERVICIO DE TRANSFER');
    });
  }

  scanZonaTransferIdaVuelta() {
    Promise.all([
      this.tarifaService.constainLocationZone(this.zonaData, this.reserva.direccionOrigenLat, this.reserva.direccionOrigenLng),
      this.tarifaService.constainLocationZone(this.zonaData, this.reserva.direccionDestinoLat, this.reserva.direccionDestinoLong)]).then(
        res => {
          [this.reserva.zonaIda, this.reserva.zonaRegreso] = res;
          if (this.reserva.zonaIda.habilitar_reserv_transfer_comp && this.reserva.zonaRegreso.habilitar_reserv_transfer_comp) {
            this.getTarifa();
          } else {
            this.alertService.send('DIRECCIÓN EN ZONA INHABILITADA PARA TRANSFER COMPARTIDO');
          }
        },
        err => {
          this.cleanZonaReserva();
          this.alertService.send('UNA DE LAS DIRECCIONES NO SE ENCUENTRA DISPONIBLE PARA SERVICIO DE TRANSFER');
        }
      );
  }

  scanZonaNormal() {
    this.tarifaService.constainLocationZone(this.zonaData, this.reserva.direccionOrigenLat, this.reserva.direccionOrigenLng).then(
      (zona: ZonaData) => {
        this.reserva.zonaIda = zona;
        this.getTarifa();
      }
    ).catch((err) => {
      this.cleanZonaReserva();
      this.getTarifa();
    });
  }

  scanZonaNormalIdaVuelta() {
    Promise.all([
      this.tarifaService.constainLocationZone(this.zonaData, this.reserva.direccionOrigenLat, this.reserva.direccionOrigenLng),
      this.tarifaService.constainLocationZone(this.zonaData, this.reserva.direccionDestinoLat, this.reserva.direccionDestinoLong)]).then(
        res => {
          [this.reserva.zonaIda, this.reserva.zonaRegreso] = res;
          this.getTarifa();
        },
        err => {
          this.cleanZonaReserva();
          this.getTarifa();
        }
      );
  }

  cleanZonaReserva() {
    this.reserva.zonaIda = null;
    this.reserva.zonaRegreso = null;
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  getTarifa() {
    if (this.reserva.tipoServicio === 'auto-pv' && this.reserva.detalleServicio === 'normal-ida') {
      this.calculoTarifaAutoIda();
    }
    if (this.reserva.tipoServicio === 'auto-pv' && this.reserva.detalleServicio === 'normal-ida-vuelta') {
      this.calculoTarifaAutoIdaVuelta();
    }
    if (this.reserva.tipoServicio === 'van-pv' && this.reserva.detalleServicio === 'normal-ida') {
      this.calculoTarifaVanIda();
    }
    if (this.reserva.tipoServicio === 'van-pv' && this.reserva.detalleServicio === 'normal-ida-vuelta') {
      this.calculoTarifaVanIdaVuelta();
    }
    if (this.reserva.tipoServicio === 'transfer') {
      this.calculoTarifaTransferCompartido(this.reserva.detalleServicio);
    }
  }

  calculoTarifaTransferCompartido(tipo: string) {
    switch (tipo) {
      case 'transfer-ida':
        this.tarifaService.getTransfer(
          this.reserva.adultos,
          this.reserva.menores,
          this.reserva.zonaIda).subscribe(
            data => {
              if (data.status) {
                this.reserva.tarifa = data.tarifa;
              } else {
                this.reserva.tarifa = 0;
                this.reserva.horaRecogida = null;
                this.alertService.send('LA DIRECCIÓN NO SE ENCUENTRA DISPONIBLE PARA SERVICIO DE TRANSFER');
              }
            },
            err => {
              console.log('Error al solicitar tarifa');
            }
          );
        break;
      case 'transfer-ida-vuelta':
        forkJoin([
          this.tarifaService.getTransfer(
            this.reserva.adultos,
            this.reserva.menores,
            this.reserva.zonaIda),
          this.tarifaService.getTransfer(
            this.reserva.adultos,
            this.reserva.menores,
            this.reserva.zonaRegreso)
        ]).subscribe(
          data => {
            if (data[0].status && data[1].status) {
              const tarifa = (data[0].tarifa + data[1].tarifa);
              this.reserva.tarifa = typeof tarifa === 'number' ? tarifa : 0;
            } else {
              this.reserva.tarifa = 0;
              this.reserva.horaRecogida = null;
              this.alertService.send('LA DIRECCIÓN NO SE ENCUENTRA DISPONIBLE PARA SERVICIO DE TRANSFER');
            }
          },
          err => {
            this.alertService.send('ERROR AL PROCESAR TARIFA');
          });
        break;
      default:
        this.alertService.send('ERROR SERVER AL CONSULTAR TARIFA');
        break;
    }
  }

  calculoTarifaAutoIda() {
    this.tarifaService.getAuto(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda).subscribe(
        data => {
          this.reserva.tarifa = data.tarifa;
        },
        err => {
          this.alertService.send('ERROR SERVER AL CONSULTAR TARIFA');
        }
      );
  }

  calculoTarifaAutoIdaVuelta() {
    forkJoin([this.tarifaService.getAuto(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda),
    this.tarifaService.getAuto(
      this.reserva.direccionDestinoLat,
      this.reserva.direccionDestinoLong,
      this.reserva.detalleServicio,
      this.reserva.zonaRegreso)])
      .subscribe(
        res => {
          this.reserva.tarifa = (res[0].tarifa + res[1].tarifa);
        },
        err => {
          this.reserva.tarifa = 0;
          this.alertService.send('ERROR FORK JOIN AL PROCESAR TARIFA');
        });
  }

  calculoTarifaVanIda() {
    this.tarifaService.getVan(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda).subscribe(
        data => {
          this.reserva.tarifa = data.tarifa;
        },
        err => {
          this.alertService.send('ERROR AL SOLICITAR TARIFA');
        }
      );
  }

  calculoTarifaVanIdaVuelta() {
    forkJoin([this.tarifaService.getVan(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda),
    this.tarifaService.getVan(
      this.reserva.direccionDestinoLat,
      this.reserva.direccionDestinoLong,
      this.reserva.detalleServicio,
      this.reserva.zonaRegreso)])
      .subscribe(
        res => {
          this.reserva.tarifa = (res[0].tarifa + res[1].tarifa);
        },
        err => {
          this.reserva.tarifa = 0;
          this.alertService.send('ERROR FORK JOIN AL PROCESAR TARIFA');
        });
  }
}
