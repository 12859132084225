import { Injectable } from '@angular/core';
import { Reserva } from '../models/reserva.modelo';

@Injectable({
  providedIn: 'root'
})
export class FormatReservaService {

  reserva: Reserva = new Reserva();

  constructor() { }

  formatDataClassReserva(data: any): Reserva {
    this.reserva.idReserva = data.id_reserva;
    this.reserva.numeroOrdenCompra = data.id_orden_compra;
    this.reserva.tipoServicio = data.servicio;
    this.reserva.fechaIda = data.fecha_reserva;
    this.reserva.sentidoViaje = data.trayecto;
    this.reserva.nombrePasajero = data.nombre;
    this.reserva.telefonoPasajero = data.telefono;
    this.reserva.emailPasajero = data.email;
    this.reserva.direccionOrigen = data.direccion;
    this.reserva.direccionDestino = data.direccion_esp;
    this.reserva.detalleDireccionOrigen = data.detalle_direccion;
    this.reserva.detalleViaje = data.info_adicional;
    this.reserva.adultos = data.adultos ? data.adultos : 0;
    this.reserva.menores = data.menores ? data.menores : 0;
    this.reserva.infantes = data.infantes ? data.infantes : 0;
    this.reserva.estadoPago = data.estado_pago;
    this.reserva.fechaPago = data.fecha_pago;
    this.reserva.opcionPago = data.opcion_pago;
    this.reserva.codigoReserva = data.codigo;
    this.reserva.vuelo1 = data.codigo_vuelo;
    this.reserva.detalleVuelo1 = data.vuelo;
    this.reserva.tarifa = data.valor;
    this.reserva.detalleServicio = data.tipo_reserva;
    this.reserva.fechaIda = data.fecha_servicio;
    this.reserva.horaRecogida = data.hora_servicio;
    this.reserva.horaVueltaEsp = data.hora_vuelta_esp;
    this.reserva.horaEstimadaServ = data.hora_estimada_servicio;
    this.reserva.pasaHoraMax = data.sobrepasa_hora_max_esp;
    this.reserva.direccionOrigenLat = data.latitud;
    this.reserva.direccionDestinoLat = data.latitud_esp;
    this.reserva.direccionOrigenLng = data.longitud;
    this.reserva.direccionDestinoLong = data.longitud_esp;
    this.reserva.anulada = data.anulada;
    this.reserva.detalleVuelo1 = data.vuelo;
    this.reserva.empresa = data.empresa;
    this.reserva.estadoRuta = data.estado_ruta;
    this.reserva.idChofer = data.id_chofer;
    this.reserva.nombreChofer = data.nombre_chofer;
    this.reserva.cotizacion = data.cotizacion;
    this.reserva.alerta_zona_ida = data.alerta_zona_cliente;

    return this.reserva;
  }
}

