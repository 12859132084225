import { Component, OnInit } from '@angular/core';
import { Usuario } from '../models/usuario.modelo';
import { AlertasService } from '../services/alertas.service';
import { UsuariosService } from '../services/usuarios.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  usuario: Usuario = new Usuario();
  regexTelefono = new RegExp('^[0-9]{9,9}$');
  regexMail: RegExp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  dataUsuarios: [];

  constructor(
    private alertService: AlertasService,
    private userService: UsuariosService
  ) {
    this.getUsuarios();
  }

  ngOnInit() {
    this.usuario.tipoUsuario = 'chofer';
    this.usuario.email = '';
  }


  verPassword(option: boolean): void {
    if (option) {
      this.alertService.send(`CONTRASEÑA: ${this.usuario.password}`, 'success');
    } else {
      this.alertService.send(`CONTRASEÑA: ${this.usuario.confirmarPassword}`, 'success');
    }
  }

  agregarUsuario(): void {
    if (
      this.usuario.nombre &&
      this.usuario.telefono &&
      this.usuario.usuario &&
      this.usuario.password &&
      this.usuario.confirmarPassword
    ) {
      if (!this.regexTelefono.test(this.usuario.telefono.toString())) {
        this.alertService.send('TELEFONO NO VALIDO', 'alert');
      } else if (this.usuario.password !== this.usuario.confirmarPassword) {
        this.alertService.send('LA CONTRASEÑA NO ES IGUAL', 'alert');
      } else {
        this.userService.crearUsuario(this.usuario).subscribe(
          res => {
            if (res.status) {
              this.getUsuarios();
              this.alertService.send('NUEVO USUARIO CREADO', 'alert');
              this.limpiarCampos();
            } else {
              this.alertService.send('ERROR AL CREAR USUARIO', 'alert');
            }
          },
          error => {
            this.alertService.send('ERROR AL CREAR USUARIO', 'alert');
          }
        );
      }
    } else {
      this.alertService.send('FALTA UN CAMPO OBLIGATORIO', 'alert');
    }
  }

  editarUsuario(idUsuario: number) {
    let x: any = this.dataUsuarios.find(({ id_usuario }) => id_usuario === idUsuario);
    this.usuario.idUsuario = x.id_usuario;
    this.usuario.tipoUsuario = x.tipo_usuario;
    this.usuario.nombre = x.nombre;
    this.usuario.telefono = x.telefono;
    this.usuario.email = x.email;
    this.usuario.usuario = x.usuario;
    this.usuario.password = x.password;
    this.usuario.confirmarPassword = x.password;
  }

  limpiarCampos(): void {
    this.usuario.nombre = '';
    this.usuario.telefono = '';
    this.usuario.email = '';
    this.usuario.usuario = '';
    this.usuario.password = '';
    this.usuario.confirmarPassword = '';
  }

  actualizarUsuario(): void {
    if (
      this.usuario.nombre &&
      this.usuario.telefono &&
      this.usuario.usuario &&
      this.usuario.password &&
      this.usuario.confirmarPassword
    ) {
      if (!this.regexTelefono.test(this.usuario.telefono.toString())) {
        this.alertService.send('TELEFONO NO VALIDO', 'alert');
      } else if (this.usuario.password !== this.usuario.confirmarPassword) {
        this.alertService.send('LA CONTRASEÑA NO ES IGUAL', 'alert');
      } else {
        this.userService.updateUsuario(this.usuario).subscribe(
          res => {
            if (res.status) {
              this.getUsuarios();
              this.alertService.send('USUARIO ACTUALIZADO', 'alert');
              this.limpiarCampos();
            } else {
              this.alertService.send('ERROR AL ACTUALIZAR USUARIO', 'alert');
            }
          },
          error => {
            this.alertService.send('USUARIO NO ACTUALIZADO', 'alert');
          }
        );
      }
    } else {
      this.alertService.send('FALTA UN CAMPO OBLIGATORIO', 'alert');
    }
  }

  borrarUsuario(idUsuario: number): void {
    this.userService.deleteUsuario(idUsuario).subscribe(
      res => {
        if (res.status) {
          this.alertService.send('USUARIO ELIMINADO', 'alert');
          this.getUsuarios();
        } else {
          this.alertService.send('ERROR AL ELIMINAR USUARIO', 'alert');
        }
      },
      error => {
        this.alertService.send('ERROR EN SERVER', 'alert');
      }
    );
  }


  getUsuarios(): void {
    this.userService.getUsuarios().subscribe(
      res => {
        this.dataUsuarios = res;
      },
      error => {
        this.alertService.send('ERROR AL OBTENER USUARIOS', 'alert');
      }
    );
  }
}
