import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { DetalleReservaComponent } from './detalle-reserva/detalle-reserva.component';
import { GenerarReservaComponent } from './generar-reserva/generar-reserva.component';
import { DetalleReservaEspecialComponent } from './detalle-reserva-especial/detalle-reserva-especial.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { BannerHomeComponent } from './banner-home/banner-home.component';
import { VuelosComponent } from './vuelos/vuelos.component';
import { RutasComponent } from './rutas/rutas.component';
import { ChoferComponent } from './chofer/chofer.component';
import { GenerarReservaNormalComponent } from './generar-reserva-normal/generar-reserva-normal.component';
import { ZonasComponent } from './zonas/zonas.component';

const routes: Routes = [
  { path: '', component: SignInComponent },
  {
    path: 'admin', component: AdminComponent,
    children: [
      { path: 'main', component: MainComponent },
      { path: 'detalle_normal', component: DetalleReservaComponent },
      { path: 'detalle_especial', component: DetalleReservaEspecialComponent },
      { path: 'generar_especial', component: GenerarReservaComponent },
      { path: 'generar_normal', component: GenerarReservaNormalComponent },
      { path: 'configuracion', component: ConfiguracionComponent },
      { path: 'banner', component: BannerHomeComponent },
      { path: 'vuelos', component: VuelosComponent },
      { path: 'rutas', component: RutasComponent },
      { path: 'usuarios', component: UserComponent },
      { path: 'zonas', component: ZonasComponent },
    ]
  },
  { path: 'chofer', component: ChoferComponent }
  //{ path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
