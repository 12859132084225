import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { Reserva } from '../models/reserva.modelo';

@Injectable({
  providedIn: 'root'
})

export class DataReservaService {

  reserva1: Reserva;
  reserva2: Reserva;
  reserva3: Reserva;

  constructor(private http: HttpClient) { }

  getReservasRango(fecha1: any, fecha2: any): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/admin/get_reserva/rango/${fecha1}/${fecha2}`);
  }

  getReservasMes(): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/admin/get_reservas_mes`);
  }

  updateReserva(reserva: Reserva): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/update_reserva`, reserva);
  }

  createReservaEspecial(reserva: Reserva): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/create_reserva_especial`, reserva);
  }

  createReservaNormal(reserva: Reserva): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/create_reserva_normal`, reserva);
  }

  updateReservaEspecial(reserva: Reserva): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/update_reserva_especial`, reserva);
  }

  deleteReserva(id: number): Observable<any> {
    return this.http.delete(`${environment.APIEndpoint}/admin/delete_reserva/${id}`);
  }

  getCalculoTarifaKM(lat1: number, lng1: number, lat2: number, lng2: number, servicio: string): Observable<any> {

    const latitud1: string = this.coordenadaFormatApi(lat1);
    const longitud1: string = this.coordenadaFormatApi(lng1);

    const latitud2: string = this.coordenadaFormatApi(lat2);
    const longitud2: string = this.coordenadaFormatApi(lng2);

    return this.http.get(`${environment.APIEndpoint}/admin/tarifa_especial/${latitud1}/${longitud1}/${latitud2}/${longitud2}/${servicio}`);
  }

  coordenadaFormatApi(coordenada: number): string {
    const x: string = coordenada.toString();
    const coordenadaStr = x.replace('.', '_');
    return coordenadaStr;
  }


  setReserva1(res: Reserva) {
    this.reserva1 = res;
  }

  getReserva1(): Reserva {
    return this.reserva1;
  }

  setReserva2(res: Reserva) {
    this.reserva2 = res;
  }

  getReserva2(): Reserva {
    return this.reserva2;
  }

  setReserva3(res: Reserva) {
    this.reserva3 = res;
  }

  getReserva3(): Reserva {
    return this.reserva3;
  }

}
