import { Component, OnInit, Injectable } from '@angular/core';
import { VueloService } from '../services/vuelo.service';
import { Vuelos } from '../models/vuelos.modelo';
import { NgbDateStruct, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertasService } from '../services/alertas.service';

const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    monthsLg: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  }

};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


/**
 * FORMATO HORA TIMEPICKER
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}


@Component({
  selector: 'app-vuelos',
  templateUrl: './vuelos.component.html',
  styleUrls: ['./vuelos.component.css'],
  providers: [I18n, {
    provide: NgbDatepickerI18n,
    useClass: CustomDatepickerI18n
  },
    {
      provide: NgbTimeAdapter,
      useClass: NgbTimeStringAdapter
    }]
})
export class VuelosComponent implements OnInit {

  // DATEPICKER
  model: NgbDateStruct;

  fileToUpload: File = null;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number;

  btnActualizar: boolean;
  btnAgregar: boolean;

  vuelos: Vuelos[];
  vuelo: Vuelos = new Vuelos();


  constructor(public vuelos_service: VueloService, private alertService: AlertasService) {
    this.cargarRegistros();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  cargarRegistros() {
    this.vuelos_service.getVuelos().subscribe(
      data => {
        this.vuelos = data;
        this.collectionSize = this.vuelos.length;
      },
      error => {
        console.log(error);
      });
  }

  ngOnInit() {
    this.vuelo.empresa_vuelo = 'JetSMART';
    this.vuelo.direccion_vuelo = 'valdivia-destino';
    this.vuelo.id_vuelo = null;
    this.vuelo.codigo_vuelo = null;
    this.vuelo.fecha_vuelo = null;
    this.vuelo.hora_salida = null;
    this.vuelo.hora_llegada = null;
    this.vuelo.hora_presentacion = null;
    this.vuelo.hora_terminal = null;
    this.vuelo.hora_ventana = '09:00:00';
    this.vuelo.habilitar_vuelo = 1;
    this.model = null;
    this.btnActualizar = true;
    this.btnAgregar = false;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this.vuelos_service.uploadVuelos(this.fileToUpload).subscribe(
      data => {
        this.alertService.send('CARGA EXCEL COMPLETA', 'success');
        this.cargarRegistros();
      }, error => {
        this.alertService.send('ERROR AL CARGAR EXCEL', 'alert');
      });
  }

  eliminarRegistroVuelo(id_vuelo: number) {
    this.vuelos_service.deleteVuelos(id_vuelo).subscribe(
      res => {
        this.alertService.send('REGISTRO VUELO ELIMINADO', 'success');
        this.cargarRegistros();
      },
      error => {
        this.alertService.send('ERROR AL BORRAR REGISTRO', 'alert');
      }
    );
  }

  actualizarRegistroVuelo() {
    if (this.vuelo.codigo_vuelo !== undefined && this.vuelo.fecha_vuelo) {
      if (!this.vuelo.hora_salida && !this.vuelo.hora_llegada) {
        this.alertService.send('ERROR: CAMPO REQUERIDO VACÍO', 'alert');
      } else {
        this.vuelos_service.updateVuelos(this.vuelo).subscribe(
          res => {
            if (res.status) {
              this.alertService.send('VUELO ACTUALIZADO EXITOSAMENTE', 'success');
              this.cargarRegistros();
              this.ngOnInit();
            } else {
              this.alertService.send('ERROR AL ACTUALIZAR VUELO', 'alert');
            }
          },
          error => {
            this.alertService.send(error, 'alert');
          }
        );
      }
    } else {
      this.alertService.send('ERROR: CAMPO REQUERIDO VACÍO', 'alert');
    }
  }


  editarRegistroVuelo(id_vuelo: number) {
    const vuelo = this.vuelos.find(x => x.id_vuelo === id_vuelo);
    this.vuelo.empresa_vuelo = vuelo.empresa_vuelo;
    this.vuelo.direccion_vuelo = vuelo.direccion_vuelo;
    this.vuelo.id_vuelo = vuelo.id_vuelo;
    this.vuelo.codigo_vuelo = vuelo.codigo_vuelo;
    this.model = this.dateModel(new Date(vuelo.fecha_vuelo));
    this.vuelo.fecha_vuelo = vuelo.fecha_vuelo;
    this.vuelo.hora_salida = vuelo.hora_salida;
    this.vuelo.hora_llegada = vuelo.hora_llegada;
    this.vuelo.hora_presentacion = vuelo.hora_presentacion;
    this.vuelo.hora_terminal = vuelo.hora_terminal;
    this.vuelo.hora_ventana = vuelo.hora_ventana;
    this.vuelo.habilitar_vuelo = vuelo.habilitar_vuelo;
    this.btnActualizar = false;
    this.btnAgregar = true;
  }

  cancelar() {
    this.cargarRegistros();
    this.ngOnInit();
    this.model = null;
    this.btnActualizar = true;
    this.btnAgregar = false;
  }

  ngbDateToString(date: NgbDate): string {
    return `${date.year}-${date.month}-${date.day}`;
  }

  calendarFechaIda(date: NgbDate) {
    this.vuelo.fecha_vuelo = this.ngbDateToString(date);
  }

  dateModel(date: Date): NgbDateStruct {
    return date ? {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate()
    } : null;
  }

  public meridian = true;

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  agregarVuelo() {
    if (this.vuelo.codigo_vuelo && this.vuelo.fecha_vuelo) {
      if (!this.vuelo.hora_salida && !this.vuelo.hora_llegada) {
        this.alertService.send('ERROR: CAMPO REQUERIDO VACÍO', 'alert');
      } else {
        this.vuelos_service.createVuelos(this.vuelo).subscribe(
          res => {
            if (res.status) {
              this.alertService.send('VUELO GUARDADO', 'success');
              this.cargarRegistros();
              this.ngOnInit();
            } else {
              this.alertService.send('ERROR AL GUARDAR VUELO', 'alert');
            }
          },
          error => {
            this.alertService.send(error, 'alert');
          }
        );
      }
    } else {
      this.alertService.send('ERROR: CAMPO REQUERIDO VACÍO', 'alert');
    }
  }
}
