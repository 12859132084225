import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Configuracion } from '../models/variables-configuracion.modelo';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  constructor(private http: HttpClient) { }

  getVariablesConfig(): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/admin/variables_configuracion`);
  }

  saveVariablesConfig(variables: Configuracion): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/variables_configuracion`, variables);
  }

}
