import { Component, OnInit, NgZone } from '@angular/core';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material';
import { ModalEditZonasComponent, ZonaData } from '../modal-edit-zonas/modal-edit-zonas.component';
import { ZonasService } from '../services/zonas.service';
import { Router } from '@angular/router';
declare const google: any;


@Component({
  selector: 'app-zonas',
  templateUrl: './zonas.component.html',
  styleUrls: ['./zonas.component.css']
})
export class ZonasComponent implements OnInit {

  zonasData: ZonaData[];

  map: AgmMap;

  zone: NgZone;

  constructor(
    public dialog: MatDialog,
    zone: NgZone,
    private zonaService: ZonasService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.zone = zone;
    this.mapsAPILoader.load().then(() => {
      this.zonaService.getZonas().subscribe(
        res => {
          if (res) {
            res.data.forEach((point: ZonaData) => {
              point.coordenadas = this.formatCoordenadas(point.coordenadas, 'array');
              const poly = new google.maps.Polygon({
                paths: point.coordenadas,
                strokeColor: '#101a9d',
                strokeOpacity: 0.45,
                strokeWeight: 2,
                fillColor: '#101a9d',
                fillOpacity: 0.35,
                editable: true
              });
              google.maps.event.addListener(poly, 'click', () => {
                point.coordenadas = poly.getPath(0).getArray();
                this.openDialog(point);
              });
              google.maps.event.addListener(poly.getPath(), 'insert_at', (index, obj) => {
                point.coordenadas = poly.getPath(0).getArray();
              });
              google.maps.event.addListener(poly.getPath(), 'set_at', (index, obj) => {
                point.coordenadas = poly.getPath(0).getArray();
              });
              poly.setMap(this.map);
            });
          }
        },
        error => {
          console.log(error);
        }
      );
    });
  }



  onMapReady(map: AgmMap) {

    this.map = map;

    this.initDrawingManager(map);
  }

  formatCoordenadas(coordenadas: any, convertType: 'string' | 'array'): any {
    if (convertType === 'array') {
      const coords: Array<any> = [];
      const points = coordenadas.split('|');
      points.forEach(e => {
        const x = e.split(',');
        coords.push({ lat: Number(x[0]), lng: Number(x[1]) });
      });
      return coords;
    }

    if (convertType === 'string') {
      let coords = '';
      let sw = true;
      for (const i of coordenadas) {
        const point = String((i.toJSON().lat).toFixed(6) + ',' + (i.toJSON().lng).toFixed(6));
        if (sw) {
          coords += point;
          sw = false;
        } else {
          coords += '|' + point;
        }
      }
      return coords;
    }
  }

  polygon: any;
  center: any = {
    lat: -39.813968,
    lng: -73.234897
  };

  pointList: { lat: number; lng: number }[] = [];

  drawingManager: any;

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  openDialog(pointData: ZonaData): void {
    this.zone.run(() => {
      const dialogRef = this.dialog.open(ModalEditZonasComponent, {
        width: '245px',
        data: { ...pointData }
      });

      dialogRef.afterClosed().subscribe((result: ZonaData) => {
        if (result) {
          const confm = confirm('Selecciona aceptar para guardar los cambios en la zona');
          if (confm === true) {
            result.coordenadas = this.formatCoordenadas(result.coordenadas, 'string');
            if (result.id_zona === null || result.id_zona === undefined) {
              this.zonaService.createZona(result).subscribe(
                res => {
                  if (res.status) {
                    console.log(res.status);
                    this.redirectTo('admin/zonas');
                  }
                },
                error => {
                  console.log(error);
                }
              );
            } else {
              this.zonaService.updateZona(result).subscribe(
                res => {
                  if (res.status) {
                    console.log(res.status);
                    this.redirectTo('admin/zonas');
                  }
                },
                error => {
                  console.log(error);
                }
              );
            }
          }
        }
      });
    });
  }

  ngOnInit() {
  }

  markerDragEnd(map) {
    console.log(map);
  }

  initDrawingManager = (map: any) => {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        clickable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        event.overlay.setOptions({ id: 20, fillColor: 'green' });
        google.maps.event.addListener(event.overlay, 'click', () => {
          const point: ZonaData = {
            id_zona: null,
            titulo: '',
            coordenadas: event.overlay.getPath(0).getArray(),
            tiempo_estimado: null,
            tiempo_estimado_unidad: 'minutos',
            valor_zona_extra: null,
            restriccion_ruta: '',
            habilitar_reserv_transfer_comp: 1
          };

          this.openDialog(point);
        });
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
        }
      }
    );
  }

  showDetails() {
    console.log('mensaje');
  }

  updatePointList(path) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        path.getAt(i).toJSON()
      );
    }
    google.maps.event.addListener(path, 'click', () => {
      console.log('hola');
    });
    console.log(this.pointList);
  }
}


