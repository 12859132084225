import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ZonaData } from '../modal-edit-zonas/modal-edit-zonas.component';

@Injectable({
  providedIn: 'root'
})
export class ZonasService {

  constructor(public http: HttpClient) { }

  getZonas(): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/admin/get_zonas`);
  }

  deleteZona(idZona: number): Observable<any> {
    return this.http.delete(`${environment.APIEndpoint}/admin/delete_zona/${idZona}`);
  }

  createZona(zona: ZonaData): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/create_zona`, zona);
  }

  updateZona(zona: ZonaData): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/admin/update_zona`, zona);
  }
}
