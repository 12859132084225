export class Configuracion {
  tarifa_auto_pv: number = null;
  tarifa_van_pv: number = null;
  descuento_auto: number = null;
  descuento_van: number = null;
  descuento_auto_ida_vuelta: number = null;
  descuento_van_ida_vuelta: number = null;
  descuento_transfer: number = null;
  auto_pv_tar1: number = null;
  auto_pv_tar2: number = null;
  auto_pv_tar3: number = null;
  auto_pv_tar4: number = null;
  auto_pv_tar5: number = null;
  van_pv_tar1: number = null;
  van_pv_tar2: number = null;
  van_pv_tar3: number = null;
  van_pv_tar4: number = null;
  van_pv_tar5: number = null;
  auto3_tar1: number = null;
  auto3_tar2: number = null;
  auto3_tar3: number = null;
  auto3_tar4: number = null;
  auto3_tar5: number = null;
  van7_tar1: number = null;
  van7_tar2: number = null;
  van7_tar3: number = null;
  van7_tar4: number = null;
  van7_tar5: number = null;
  van12_tar1: number = null;
  van12_tar2: number = null;
  van12_tar3: number = null;
  van12_tar4: number = null;
  van12_tar5: number = null;
  van14_tar1: number = null;
  van14_tar2: number = null;
  van14_tar3: number = null;
  van14_tar4: number = null;
  van14_tar5: number = null;
  van16_tar1: number = null;
  van16_tar2: number = null;
  van16_tar3: number = null;
  van16_tar4: number = null;
  van16_tar5: number = null;
  tarifa_min_auto3: number = null;
  tarifa_min_van7: number = null;
  tarifa_min_van12: number = null;
  tarifa_min_van14: number = null;
  tarifa_min_van16: number = null;
  descuento_esp_ida_vuelta: number = null;
  tarifa_min_normal: number = null;
  peaje_auto: number = null;
  peaje_van: number = null;
  dist_km_peaje: number = null;
  dist_min_tar1: number = null;
  dist_max_tar1: number = null;
  dist_min_tar2: number = null;
  dist_max_tar2: number = null;
  dist_min_tar3: number = null;
  dist_max_tar3: number = null;
  dist_min_tar4: number = null;
  dist_max_tar4: number = null;
  dist_min_tar5: number = null;
  dist_max_tar5: number = null;
  aumento_alta_demanda: number = null;
  horas_ventana: number = null;
  reservas_max_ventana: number = null;
  servicio_auto: boolean = false;
  servicio_van: boolean = false;
  servicio_transfer: boolean = false;
  servicio_especial: boolean = false;
  valor_primer_pasajero: number = null;
  valor_pasajero_1_8: number = null;
  valor_pasajero_9_12: number = null;
  valor_pasajero_mayor_12: number = null;
  valor_pasajero_nino: number = null;
  valor_pasajero_infante: number = null;
  terminos_reserva: string = null;
  horas_ventana_auto_pv: number = null;
  horas_ventana_van_pv: number = null;
}
