/// <reference types="googlemaps" />
import { Component, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { UsuariosService } from '../services/usuarios.service';
import { RutasService } from '../services/rutas.service';
import { AlertasService } from '../services/alertas.service';


@Component({
  selector: 'app-rutas',
  templateUrl: './rutas.component.html',
  styleUrls: ['./rutas.component.css']
})

export class RutasComponent implements OnInit {

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private userService: UsuariosService,
    private rutaService: RutasService,
    private alertService: AlertasService) {
  }

  queryRuta: any = {
    horaDesde: '',
    horaHasta: '',
    fechaViaje: '',
    idChofer: '',
    direccion: ''
  };

  iconCustomerPendiente = {
    url: '../../assets/iconmap_rojo.svg',
    scaledSize: {
      width: 50,
      height: 50
    }
  };

  iconCustomerAsignada = {
    url: '../../assets/iconmap_verde.svg',
    scaledSize: {
      width: 50,
      height: 50
    }
  };

  iconCustomerFinalizada = {
    url: '../../assets/iconmap_azul.svg',
    scaledSize: {
      width: 50,
      height: 50
    }
  };

  latitude = -39.813968;
  longitude = -73.234897;
  zoom = 14;

  dataRutas: any[];

  choferes: any[];


  ngOnInit() {
    this.userService.getChoferes().subscribe(
      res => {
        this.choferes = res;
      },
      error => {
        this.alertService.send('ERROR AL OBTENER LISTA DE CHOFERES', 'alert');
      }
    );

    this.queryRuta.direccion = 'todos';
    this.queryRuta.idChofer = 'todos';
    this.queryRuta.horaDesde = '08:00';
    this.queryRuta.horaHasta = '20:00';
    this.queryRuta.fechaViaje = this.hoyFecha();
    this.consultarRutas();
  }

  consultarRutas(): void {
    this.rutaService.queryRutas(this.queryRuta).subscribe(
      res => {
        this.dataRutas = res;
        console.log(this.dataRutas);
      },
      error => {
        this.alertService.send('RUTAS NO ENCONTRADAS', 'warning');
      }
    );
  }

  formatCantPasajeros(pasajeros: any): string {
    let adultos = pasajeros.adultos ? pasajeros.adultos : '0';
    let menores = pasajeros.menores ? pasajeros.menores : '0';
    let infantes = pasajeros.infantes ? pasajeros.infantes : '0';
    return `${adultos}a + ${menores}m + ${infantes}i`;
  }


  asignarRutaBtn(idChofer: string, idReserva: number, mensaje: string): void {
    if (idChofer !== 'no_seleccionado') {

      const variables = {
        id_chofer: idChofer,
        id_reserva: idReserva,
        mensaje_chofer: mensaje ? mensaje : ''
      };

      this.rutaService.asignarRuta(variables).subscribe(
        res => {
          if (res.status) {
            this.alertService.send('RUTA ASIGNADA EXITOSAMENTE', 'success');
            this.consultarRutas();
          }
          console.log(res);
        },
        error => {
          this.alertService.send('ERROR AL ASIGNAR RUTA', 'alert');
          console.log(error);
        }
      );
    } else {
      this.alertService.send('SELECCIONE UN CHOFER ANTES DE ASIGNAR RUTA', 'warning');
    }
  }

  cancelarRuta(idReserva: number): void {
    this.rutaService.cancelarRuta(idReserva).subscribe(
      res => {
        this.alertService.send('RUTA CANCELADA', 'success');
        this.consultarRutas();
      },
      error => {
        this.alertService.send('ERROR AL CANCELAR RUTA', 'alert');
      }
    );
  }

  hoyFecha(): any {
    const hoy: Date = new Date();
    let dd: any = hoy.getDate();
    let mm: any = hoy.getMonth() + 1;
    const yyyy: any = hoy.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    return { year: yyyy, month: mm, day: dd };
  }

}
