import { Component, OnInit, Injectable } from '@angular/core';
import { DataReservaService } from '../services/data-reserva.service';
import { Reserva } from '../models/reserva.modelo';
import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
  NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AlertasService } from '../services/alertas.service';

/**
 * VALORES ESPAÑOL DATEPICKER
 */
const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ],
    monthsLg: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ]
  }
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

/**
 * FORMATO HORA TIMEPICKER
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    //horas sin segundos
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
    //horas con segundos
    //return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

@Component({
  selector: 'app-generar-reserva',
  templateUrl: './generar-reserva.component.html',
  styleUrls: ['./generar-reserva.component.css'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }
  ]
})
export class GenerarReservaComponent implements OnInit {

  //DATEPICKER
  model_ida_fecha: NgbDateStruct;
  model_idaVuelta_fecha1: NgbDateStruct;
  model_idaVuelta_fecha2: NgbDateStruct;

  dia: string;
  mes: string;
  year: string;

  distancia: any = null;
  tiempo: any = null;
  procesandoTarifa = false;

  model;

  selectToday() {
    this.model_ida_fecha = this.calendar.getToday();
    this.model_idaVuelta_fecha1 = this.calendar.getToday();
    this.model_idaVuelta_fecha2 = this.calendar.getToday();
    this.reserva.fechaIda = this.ngbDateToString(this.calendar.getToday());
    this.reserva.fechaVuelta = this.ngbDateToString(this.calendar.getToday());
  }

  calendarFechaIda(date: NgbDate) {
    this.reserva.fechaIda = this.ngbDateToString(date);
  }

  calendarFechaVuelta(date: NgbDate) {
    this.reserva.fechaVuelta = this.ngbDateToString(date);
  }

  ngbDateToString(date: NgbDate): string {
    return `${date.year}-${date.month}-${date.day}`;
  }

  reserva: Reserva = new Reserva();

  vistaServicioIdaVuelta: boolean = false;

  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;

  constructor(
    public dataReserva: DataReservaService,
    private calendar: NgbCalendar,
    private alertService: AlertasService,
    private router: Router
  ) {
    this.reserva.tipoServicio = 'auto-pv';
    this.reserva.detalleServicio = 'normal-ida';
    this.reserva.sentidoViaje = 'p-p';
    this.reserva.detalleServicio = 'especial-ida';
    this.reserva.estadoPago = false;
    this.reserva.opcionPago = 'transferencia';
    this.reserva.direccionOrigen = '';
    this.reserva.direccionDestino = '';
    /*
      carga coor1denadas de inicio en mapa origen-destino
    */
    this.reserva.direccionOrigenLat = -39.813968;
    this.reserva.direccionOrigenLng = -73.234897;

    this.reserva.direccionDestinoLat = -39.813968;
    this.reserva.direccionDestinoLong = -73.234897;
  }

  ngOnInit() { }

  salirModuloEdicionReserva() {
    this.router.navigateByUrl('admin/main');
  }

  getGeoDireccionOrigen(event): void {
    this.reserva.direccionOrigen = event.direccion;
    this.reserva.direccionOrigenLat = event.latitud;
    this.reserva.direccionOrigenLng = event.longitud;
  }

  getGeoDireccionDestino(event): void {
    this.reserva.direccionDestino = event.direccion;
    this.reserva.direccionDestinoLat = event.latitud;
    this.reserva.direccionDestinoLong = event.longitud;
  }

  guardarCambios() { }

  crearReserva() {
    this.dataReserva.createReservaEspecial(this.reserva).subscribe(
      res => {
        if (res.status) {
          this.alertService.send('RESERVA GENERADA', 'success');
        } else {
          this.alertService.send('ERROR AL GENERAR RESERVA', 'alert');
        }
      },
      error => {
        this.alertService.send('ERROR AL GENERAR RESERVA', 'alert');
      }
    );
  }

  calcularTarifa() {
    this.procesandoTarifa = true;
    this.dataReserva
      .getCalculoTarifaKM(
        this.reserva.direccionOrigenLat,
        this.reserva.direccionOrigenLng,
        this.reserva.direccionDestinoLat,
        this.reserva.direccionDestinoLong,
        this.reserva.tipoServicio
      )
      .subscribe(
        res => {
          this.reserva.tarifa = res.total;
          this.distancia = res.distancia;
          this.tiempo = res.tiempo;
          this.procesandoTarifa = false;
        },
        error => {
          this.alertService.send('ERROR CALCULANDO TARIFA', 'alert');
        }
      );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public meridian: boolean = true;

  toggleMeridian() {
    this.meridian = !this.meridian;
  }
}
