import { Component, OnInit, Injectable } from '@angular/core';
import { DataReservaService } from '../services/data-reserva.service';
import { Reserva } from '../models/reserva.modelo';
import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AlertasService } from '../services/alertas.service';

/*VALORES ESPAÑOL DATEPICKER*/
const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    monthsLg: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  }

};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


/**
 * FORMATO HORA TIMEPICKER
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

@Component({
  selector: 'app-detalle-reserva',
  templateUrl: './detalle-reserva.component.html',
  styleUrls: ['./detalle-reserva.component.css'],
  providers: [I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class DetalleReservaComponent implements OnInit {

  //DATEPICKER
  model_ida_fecha: NgbDateStruct;
  model_idaVuelta_fecha1: NgbDateStruct;
  model_idaVuelta_fecha2: NgbDateStruct;

  public meridian = true;

  dia: string;
  mes: string;
  year: string;

  model: any;

  reserva1: Reserva;
  reserva2: Reserva;

  vistaServicioIdaVuelta = false;

  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;

  selectToday() {
    this.model_ida_fecha = this.calendar.getToday();
    this.model_idaVuelta_fecha1 = this.calendar.getToday();
    this.model_idaVuelta_fecha2 = this.calendar.getToday();
    this.reserva1.fechaIda = this.ngbDateToString(this.calendar.getToday());
    this.reserva1.fechaVuelta = this.ngbDateToString(this.calendar.getToday());
  }

  calendarFechaIda(date: NgbDate) {
    this.reserva1.fechaIda = this.ngbDateToString(date);
  }

  calendarFechaVuelta(date: NgbDate) {
    this.reserva2.fechaIda = this.ngbDateToString(date);
  }

  ngbDateToString(date: NgbDate): string {
    return `${date.year}-${date.month}-${date.day}`;
  }

  constructor(
    public dataReserva: DataReservaService,
    private calendar: NgbCalendar,
    private alertService: AlertasService,
    private router: Router) {

    this.reserva1 = dataReserva.getReserva1();
    this.lat1 = +this.reserva1.direccionOrigenLat;
    this.lng1 = +this.reserva1.direccionOrigenLng;
  }

  ngOnInit() {
  }

  anularReserva() {
    this.dataReserva.deleteReserva(this.reserva1.numeroOrdenCompra).subscribe(
      res => {
        if (res.status) {
          this.alertService.send('RESERVA ANULADA', 'success');
          this.router.navigateByUrl('admin/main');
        } else {
          this.alertService.send('FALLO AL ANULAR RESERVA', 'alert');
        }
      },
      error => {
        this.alertService.send('ERROR DE CONEXION CON SERVER', 'alert');
      });
  }

  salirModuloEdicionReserva() {
    this.router.navigateByUrl('admin/main');
  }

  getGeoDireccionOrigen(event): void {
    this.reserva1.direccionOrigen = event.direccion;
    this.reserva1.direccionOrigenLat = event.latitud;
    this.reserva1.direccionOrigenLng = event.longitud;
  }

  getGeoDireccionDestino(event): void {
    this.reserva2.direccionOrigen = event.direccion;
    this.reserva2.direccionOrigenLat = event.latitud;
    this.reserva2.direccionOrigenLng = event.longitud;
  }

  guardarCambios() {
    if (this.reserva1.detalleServicio === 'normal-ida' || this.reserva1.detalleServicio === 'normal-ida-vuelta') {
      this.dataReserva.updateReserva(this.reserva1).subscribe(
        res => {
          this.alertService.send('RESERVA ACTUALIZADA', 'success');
        },
        errror => {
          this.alertService.send('ERROR EN ACTUALIZACION', 'alert');
        });
    }
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }
}
