import { Component, OnInit } from '@angular/core';
import { RutasService } from '../services/rutas.service';
import { UsuariosService } from '../services/usuarios.service';
import { Router } from '@angular/router';
import { AlertasService } from '../services/alertas.service';


@Component({
  selector: 'app-chofer',
  templateUrl: './chofer.component.html',
  styleUrls: ['./chofer.component.css']
})
export class ChoferComponent implements OnInit {

  constructor(
    private alertService: AlertasService,
    private userService: UsuariosService,
    private rutaService: RutasService,
    private router: Router) {
  }

  public nombreChofer: string;

  queryRuta: any = {
    horaDesde: '',
    horaHasta: '',
    fechaViaje: '',
    idChofer: '',
    direccion: ''
  };

  iconCustomerAsignada = {
    url: '../../assets/iconmap_verde.svg',
    scaledSize: {
      width: 50,
      height: 50
    }
  };

  iconCustomerFinalizada = {
    url: '../../assets/iconmap_azul.svg',
    scaledSize: {
      width: 50,
      height: 50
    }
  };

  latitude = -39.813968;
  longitude = -73.234897;
  zoom = 14;

  dataRutas: any[];


  ngOnInit() {

    this.nombreChofer = sessionStorage.getItem('user_name');

    this.queryRuta.direccion = 'todos';
    this.queryRuta.idChofer = sessionStorage.getItem('user_id');
    this.queryRuta.horaDesde = '08:00';
    this.queryRuta.horaHasta = '20:00';
    this.queryRuta.fechaViaje = this.hoyFecha();
    this.consultarRutas();
  }

  consultarRutas(): void {
    this.rutaService.queryRutas(this.queryRuta).subscribe(
      res => {
        this.dataRutas = res;
      },
      error => {
        this.alertService.send('RUTAS NO ENCONTRADAS', 'alert');
      }
    );
  }

  formatCantPasajeros(pasajeros: any): string {
    const adultos = pasajeros.adultos ? pasajeros.adultos : '0';
    const menores = pasajeros.menores ? pasajeros.menores : '0';
    const infantes = pasajeros.infantes ? pasajeros.infantes : '0';
    return `${adultos}a + ${menores}m + ${infantes}i`;
  }

  finalizarRuta(idReserva: number): void {
    if (confirm(`SI DESEA FINALIZAR ESTA RUTA SELECCIONE 'ACEPTAR'`)) {
      this.rutaService.finalizarRuta(idReserva).subscribe(
        res => {
          this.alertService.send('RUTA FINALIZADA', 'success');
          this.consultarRutas();
        },
        error => {
          this.alertService.send('ERROR AL INTENTAR FINALIZAR RUTA', 'alert');
        }
      );
    }
  }

  cerrarSesion(): void {
    sessionStorage.clear();
    this.router.navigateByUrl('');
  }

  hoyFecha(): any {
    const hoy: Date = new Date();
    const yyyy: any = hoy.getFullYear();
    let dd: any = hoy.getDate();
    let mm: any = hoy.getMonth() + 1;

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    return { year: yyyy, month: mm, day: dd };
  }

}
