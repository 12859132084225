export class Vuelos {
    id_vuelo: number = null;
    empresa_vuelo: string = null;
    codigo_vuelo: number = null;
    fecha_vuelo: any = null;
    direccion_vuelo: 'valdivia-destino' | 'destino-valdivia' = null;
    hora_salida: string = null;
    hora_llegada: string = null;
    hora_presentacion: string = null;
    hora_terminal: string = null;
    hora_ventana = '9:00:00';
    habilitar_vuelo: number = null;
}